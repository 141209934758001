import defaultAxios, { AxiosInstance } from "axios";
import { extrasApi } from "config";
import { useContext } from "context";
import { StateType } from "context/state";

function axiosAuth(tokenData: StateType["tokenData"]): AxiosInstance {
  const instance = defaultAxios.create();

  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = tokenData
      ? `Bearer ${tokenData.accessToken}`
      : "";
    return config;
  });

  return instance;
}

async function asyncWrapper(
  asyncRequest: () => Promise<any>,
): Promise<{ data?: any; success: boolean }> {
  try {
    const { data } = await asyncRequest();

    return {
      data,
      success: data !== undefined,
    };
  } catch (err) {
    return {
      success: false,
      data: err,
    };
  }
}

const useApiRequests = () => {
  const { tokenData } = useContext();
  const axios = axiosAuth(tokenData);

  const timeout = 4000;

  return {
    getEnrollment: () =>
      asyncWrapper(() =>
        axios.get(`${extrasApi}/battle-of-yields`, {
          timeout,
        }),
      ),
    saveEnrollment: ({
      playAddress,
      telegramAccount,
    }: {
      playAddress: string;
      telegramAccount: string;
    }) =>
      asyncWrapper(() =>
        axios.post(
          `${extrasApi}/battle-of-yields`,
          { playAddress, telegramAccount },
          { timeout },
        ),
      ),
  };
};

export default useApiRequests;
