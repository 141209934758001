import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Redirect } from "react-router-dom";
import ModalContainer from "components/ModalContainer";
import PageState from "components/PageState";
// eslint-disable-next-linec
import { maiarIdApi } from "config";
import { routeNames } from "routes";

const Maiar = () => {
  const { loggedIn } = Dapp.useContext();

  const [token, setToken] = React.useState("");
  React.useEffect(() => {
    axios.post(`${maiarIdApi}/login/init`, "").then(({ data }) => {
      setToken(data.loginToken);
    });
  }, []);

  return (
    <>
      {loggedIn ? (
        <Redirect to={routeNames.enroll} />
      ) : (
        <ModalContainer title="">
          {token ? (
            <Dapp.Pages.WalletConnect
              callbackRoute={routeNames.enroll}
              logoutRoute={routeNames.home}
              title="Maiar Login"
              lead="Start by logging in with your Maiar App"
              token={token}
            />
          ) : (
            <PageState
              title="Loading..."
              spin={true}
              svgComponent={faCircleNotch}
            />
          )}
        </ModalContainer>
      )}
    </>
  );
};

export default Maiar;
