import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Route, Switch } from "react-router-dom";
import { AccessTokenProvider } from "components/AccessTokenProvider";
import Layout from "components/Layout";
import PageNotFoud from "./components/PageNotFoud";
import * as config from "./config";
import { ContextProvider } from "./context";
import routes from "./routes";

export default function App() {
  return (
    <Dapp.Context config={config}>
      <ContextProvider>
        <AccessTokenProvider>
          <Layout>
            <Switch>
              {routes.map((route, i) => (
                <Route
                  path={route.path}
                  key={route.path + i}
                  component={route.component}
                  exact={true}
                />
              ))}
              <Route component={PageNotFoud} />
            </Switch>
          </Layout>
        </AccessTokenProvider>
      </ContextProvider>
    </Dapp.Context>
  );
}
