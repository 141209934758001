import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useContext } from "context";
import useMatchPath from "helpers/useMatchPath";
import {
  backgroundRouteNames,
  foregoundRouteNames,
  modalRouteNames,
  backgroundRoutes,
  BackgroundRoutesType,
  routeNames,
} from "routes";

const useBgPage = () => {
  const matchPath = useMatchPath();
  const dispatch = useDispatch();

  const { yieldsOrigin } = useContext();
  const { pathname, search } = useLocation();
  const [lastOrigin, setLastOrigin] = React.useState("");
  const [BgPage, setBgPage] = React.useState<React.ReactNode>(() => null);

  const isForegroundRoute = () =>
    Object.values(foregoundRouteNames).some((path) => matchPath(path));

  const [hideBgPage, setHideBgPage] = React.useState<React.ReactNode>(
    isForegroundRoute(),
  );

  const setOrigin = (path: string) => {
    dispatch({
      type: "setYieldsOrigin",
      yieldsOrigin: {
        pathname: path,
        search: /^\?[A-Za-z0-9=&]+$/.test(search) ? search : "",
      },
    });
    setLastOrigin(path);
  };

  const setyieldsOrigin = () => {
    const foundBgPath = Object.values(backgroundRouteNames).find((path) =>
      matchPath(path),
    );

    const isModalPath = Object.values(modalRouteNames).some((path) =>
      matchPath(path),
    );

    if (foundBgPath && foundBgPath !== yieldsOrigin.pathname) {
      setOrigin(pathname);
    }

    setHideBgPage(isForegroundRoute() || (!foundBgPath && !isModalPath));

    return () => {
      if (foundBgPath || lastOrigin) {
        let newPathname = pathname || lastOrigin;
        newPathname = pathname === routeNames.maiar ? lastOrigin : newPathname;
        dispatch({
          type: "setYieldsOrigin",
          yieldsOrigin: {
            pathname: newPathname,
            search: /^\?[A-Za-z0-9=]+$/.test(search) ? search : "",
          },
        });
      }
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(setyieldsOrigin, [pathname, search, lastOrigin]);

  const setExchangeBackground = () => {
    const found = Object.entries(backgroundRouteNames).find(
      ([, path]) => yieldsOrigin.pathname === path || matchPath(path),
    );
    if (found) {
      const [routeName] = found;
      const Component =
        backgroundRoutes[routeName as BackgroundRoutesType].component;
      setBgPage(() => <Component />);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(setExchangeBackground, [yieldsOrigin.pathname]);

  return { BgPage, hideBgPage };
};

export default useBgPage;
