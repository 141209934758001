import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { dAppName } from "config";
import Maiar from "./components/Maiar";
import withPageTitle from "./components/PageTitle";
import Enroll from "./pages/Enroll";
import Home from "./pages/Home";

type RouteType = Dapp.RouteType & { title: string };

export type BackgroundRoutesType = "home";
export type ModalRoutesType = "maiar" | "enroll";

export const backgroundRoutes: Record<BackgroundRoutesType, RouteType> = {
  home: {
    path: "/",
    title: "",
    component: Home,
  },
};

export const modalRoutes: Record<ModalRoutesType, RouteType> = {
  maiar: {
    path: "/maiar",
    title: "",
    component: Maiar,
  },
  enroll: {
    path: "/enroll",
    title: "",
    component: Enroll,
    authenticatedRoute: true,
  },
};

export const backgroundRouteNames = Object.keys(backgroundRoutes).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: backgroundRoutes[cur as BackgroundRoutesType].path,
  }),
  {} as Record<BackgroundRoutesType, string>,
);

export const modalRouteNames = Object.keys(modalRoutes).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: modalRoutes[cur as ModalRoutesType].path,
  }),
  {} as Record<ModalRoutesType, string>,
);

export const foregoundRouteNames = {
  doesNotExist: "/fixesBug",
};

export const routeNames = {
  ...backgroundRouteNames,
  ...modalRouteNames,
  ...foregoundRouteNames,
};

const routes: RouteType[] = [
  ...Object.keys(modalRoutes).map((route) => {
    const { path, title, authenticatedRoute, component } =
      modalRoutes[route as ModalRoutesType];
    return { path, title, authenticatedRoute, component };
  }),

  ...Object.keys(backgroundRoutes).map((route) => {
    const { path, title, authenticatedRoute } =
      backgroundRoutes[route as BackgroundRoutesType];
    return {
      path,
      title,
      authenticatedRoute,
      component: () => null,
    };
  }),
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title ? `${route.title} • ${dAppName}` : dAppName;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
