import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import routes, { routeNames } from "routes";
import useBgPage from "./useBgPage";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { loggedIn } = Dapp.useContext();
  const refreshAccount = Dapp.useRefreshAccount();
  const { BgPage, hideBgPage } = useBgPage();

  React.useEffect(() => {
    if (loggedIn) {
      refreshAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <>
      <div className="glitch">
        <div className="glitch-img"></div>
        <div className="glitch-img"></div>
        <div className="glitch-img"></div>
        <div className="glitch-img"></div>
        <div className="glitch-img"></div>
      </div>
      <div className="d-flex flex-column flex-fill wrapper">
        <main className="d-flex flex-column flex-grow-1">
          <Dapp.Authenticate routes={routes} unlockRoute={routeNames.maiar}>
            {!hideBgPage && <>{BgPage}</>}
            {children}
          </Dapp.Authenticate>
        </main>
      </div>
    </>
  );
};

export default Layout;
