import * as React from "react";
import { Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useContext } from "context";

const ModalContainer = ({
  children,
  noSpacer,
  className,
  title,
}: {
  children: React.ReactNode;
  noSpacer?: boolean;
  className?: string;
  title: React.ReactNode;
}) => {
  const { yieldsOrigin } = useContext();
  const [close, setClose] = React.useState(false);

  const handleClose = () => {
    setClose(true);
  };

  React.useEffect(() => {
    return () => {
      setClose(false);
    };
  }, []);

  return close ? (
    <Redirect
      to={{
        pathname: yieldsOrigin.pathname,
        search: yieldsOrigin.search,
      }}
    />
  ) : (
    <Modal
      show
      keyboard={false}
      backdrop="static"
      onHide={handleClose}
      className={`modal-container ${className ? className : ""}`}
      animation={false}
      centered
    >
      <div className="modal-card card w-100">
        <div className="card-title h5 mb-0">
          <div className="d-flex justify-content-between align-items-center">
            <div>{title}</div>
            <button
              type="button"
              className="btn btn-close mt-spacer mr-spacer border"
              onClick={handleClose}
            >
              <span className="h4">×</span>
            </button>
          </div>
        </div>

        <div
          className={`modal-card-body text-center ${
            noSpacer ? "p-0" : "p-spacer"
          }`}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalContainer;
