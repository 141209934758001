import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import axios from "axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import useLogout from "components/Layout/useLogout";
import { maiarIdApi } from "config";
import { useContext, useDispatch } from "context";
import { removeItem, setItem } from "storage/session";
import getProviderType from "./helpers/getProviderType";
import useFetchAccessToken from "./useFetchAccessToken";

export function useRemoveTokenData() {
  const dispatch = useDispatch();

  const removeTokenData = () => {
    removeItem("tokenData");
    dispatch({
      type: "setTokenData",
      tokenData: undefined,
    });
  };

  return { removeTokenData };
}

export const AccessTokenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    loggedIn,
    tokenLogin,
    walletConnectLogin,
    address: contextAddress,
    dapp,
  } = Dapp.useContext();

  const dispatch = useDispatch();
  const { tokenData } = useContext();
  const { search, pathname } = useLocation();
  const [refetchedOnce, setRefetchedOnce] = React.useState(false);
  const history = useHistory();
  const timoeutRef = React.useRef<any>();
  const isRefetchingRef = React.useRef(false);
  const logOut = useLogout();
  const providerType = getProviderType(dapp.provider);

  const fetchAccessToken = useFetchAccessToken();

  const checkAccessToken = async () => {
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams as any);
    const { signature, loginToken, address, ...remainingParams } = params;
    let hasTokenData = Boolean(tokenData);
    if (!hasTokenData) {
      if (providerType === "wallet" || providerType === "extension") {
        if (signature && loginToken && address) {
          hasTokenData = await fetchAccessToken({
            signature,
            loginToken,
            address,
          });

          if (hasTokenData) {
            const newUrlParams = new URLSearchParams(
              remainingParams,
            ).toString();
            const newSearch = newUrlParams ? `?${newUrlParams}` : "";
            history.replace(`${pathname}${newSearch}`);
          } else {
            logOut();
          }
        } else {
          logOut();
        }
      }

      if (walletConnectLogin && tokenLogin?.signature) {
        hasTokenData = await fetchAccessToken({
          signature: tokenLogin.signature,
          loginToken: tokenLogin.loginToken,
          address: contextAddress,
        });

        if (!hasTokenData) {
          logOut();
        }
      }
    }
  };

  React.useEffect(() => {
    if (loggedIn) {
      checkAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, tokenData, tokenLogin]);

  React.useEffect(() => {
    if (tokenData?.accessTokenExpirationTime && !isRefetchingRef.current) {
      isRefetchingRef.current = true;
      const twentyMinutes = 20 * 60 * 1000;
      const timeoutMs = refetchedOnce ? twentyMinutes : 0;
      setRefetchedOnce(true);

      timoeutRef.current = setTimeout(() => {
        axios
          .post(`${maiarIdApi}/login/access-token-generate`, {
            refreshToken: tokenData.refreshToken,
          })
          .then(({ data }) => {
            isRefetchingRef.current = false;
            const newTokenData = {
              accessToken: data.accessToken,
              accessTokenExpirationTime: data.expirationTime,
              refreshToken: tokenData.refreshToken,
            };
            setItem({
              key: "tokenData",
              data: newTokenData,
              expires: moment().add(1, "hours").unix(),
            });
            dispatch({
              type: "setTokenData",
              tokenData: newTokenData,
            });
          })
          .catch((err) => {
            console.error("Unbale to generate access token", err);
          });
      }, timeoutMs);
    }
    return () => {
      if (!loggedIn) {
        clearTimeout(timoeutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData, isRefetchingRef, loggedIn, refetchedOnce]);

  const ready = !loggedIn || Boolean(loggedIn && tokenData);

  return ready ? <>{children}</> : null;
};
