import * as React from "react";
import { Address } from "@elrondnetwork/erdjs";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { object, string } from "yup";
import PageState from "components/PageState";
import useApiRequests from "helpers/useApiRequests";

function canTransformToPublicKey(address: string) {
  try {
    const checkAddress = new Address(address);
    return Boolean(checkAddress.bech32());
  } catch {
    return false;
  }
}

function addressIsValid(destinationAddress: string) {
  const isValidBach = !(
    !destinationAddress ||
    !destinationAddress.startsWith("erd") ||
    destinationAddress.length !== 62 ||
    /^\w+$/.test(destinationAddress) !== true
  );
  return isValidBach && canTransformToPublicKey(destinationAddress);
}

const validationSchema = object().shape({
  playAddress: string()
    .required("Required")
    .test(
      "min",
      "Invalid address",
      (value) => !!(value && addressIsValid(value)),
    ),

  telegramAccount: string()
    .test("min", "Minimum 5 characters", (value) =>
      Boolean(value && value.length >= 5),
    )
    .test("max", "Maximum 65 characters", (value) =>
      Boolean(value && value.length <= 65),
    )
    .test("match", "Must start with @", (value) =>
      Boolean(value && value.match(/^@/)),
    ),
});

const EnrollForm = ({
  address,
  telegram,
  successText,
  onBack,
}: {
  address: string;
  telegram: string;
  successText: string;
  onBack?: () => void;
}) => {
  const ref = React.useRef(null);
  const inputRef = React.useRef(null);
  const { saveEnrollment } = useApiRequests();
  const initialValues = { playAddress: address, telegramAccount: telegram };
  const [enrolled, setEnrolled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  return (
    <>
      {enrolled ? (
        <PageState
          title={successText}
          className="bg-success text-white"
          svgComponent={
            <>
              <FontAwesomeIcon
                icon={faCheck}
                className="mx-auto text-white fa-3x"
              />
            </>
          }
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={({ playAddress, telegramAccount }) => {
            setLoading(true);
            saveEnrollment({ playAddress, telegramAccount }).then(
              (response) => {
                setLoading(false);
                if (response.success) {
                  setEnrolled(true);
                } else {
                  setError(response?.data?.response?.statusText);
                }
              },
            );
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit} ref={ref} className="text-left">
                <div className="form-group">
                  <label htmlFor="playAddress">Battle of Yields Wallet</label>
                  <textarea
                    id="playAddress"
                    ref={inputRef}
                    data-testid="playAddress"
                    value={values.playAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors.playAddress && touched.playAddress
                        ? "is-invalid"
                        : ""
                    }`}
                    style={{ resize: "none" }}
                  ></textarea>

                  {"playAddress" in errors && "playAddress" in touched ? (
                    <div
                      data-testid="playAddressError"
                      className="invalid-feedback"
                    >
                      {errors.playAddress}
                    </div>
                  ) : (
                    <small className="text-muted d-inline-block mt-1">
                      Your Maiar address was automatically added. You can use a
                      different one.
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="telegramAccount">
                    Input your Telegram @username
                  </label>
                  <input
                    id="telegramAccount"
                    name="telegramAccount"
                    data-testid="telegramAccount"
                    type="text"
                    value={values.telegramAccount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    className={`form-control ${
                      errors.telegramAccount && touched.telegramAccount
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {"telegramAccount" in errors &&
                  "telegramAccount" in touched ? (
                    <div
                      data-testid="telegramAccountError"
                      className="invalid-feedback"
                    >
                      {errors.telegramAccount}
                    </div>
                  ) : (
                    <small className="text-muted d-inline-block mt-1">
                      Elrond team members and admins will never DM you first.
                      Username required for handling special situations.
                    </small>
                  )}
                </div>

                <div className="d-flex align-items-center flex-column mt-5">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-primary btn-lg px-spacer"
                  >
                    {loading ? "Saving..." : "Submit"}
                  </button>
                  {onBack && (
                    <a
                      type="button"
                      href="/"
                      className="btn btn-lg btn-link"
                      onClick={(e) => {
                        e.preventDefault();
                        onBack();
                      }}
                    >
                      Back
                    </a>
                  )}
                </div>
                {error && (
                  <div className="text-danger text-center mt-3">{error}</div>
                )}
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EnrollForm;
