import axios from "axios";
import moment from "moment";
import { maiarIdApi } from "config";
import { useDispatch } from "context";
import { setItem } from "storage/session";

export default function useAccessToken() {
  const dispatch = useDispatch();

  const fetchAccessToken = async ({
    signature,
    loginToken,
    address,
  }: {
    signature: string;
    loginToken: string;
    address: string;
  }): Promise<boolean> => {
    try {
      const {
        data: { accessToken, accessTokenExpirationTime, refreshToken },
      } = await axios.post(`${maiarIdApi}/login`, {
        signature,
        loginToken,
        address,
        data: {},
      });

      const newTokenData = {
        accessToken,
        accessTokenExpirationTime,
        refreshToken,
      };

      setItem({
        key: "tokenData",
        data: newTokenData,
        expires: moment().add(1, "hours").unix(),
      });

      dispatch({
        type: "setTokenData",
        tokenData: newTokenData,
      });
      return true;
    } catch (e) {
      console.error("Unable to fetch access token", e);
      return false;
    }
  };
  return fetchAccessToken;
}
