import * as Dapp from "@elrondnetwork/dapp";

export const dAppName = "Battle of Yields";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

export const walletConnectBridge =
  "https://wallet-connect-fra-sock.elrond.com/wallet-connect-0";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/";

export const maiarIdApi = "https://id.maiarbrowser.com/api/v1";
export const extrasApi = "https://extras-api.elrond.com";

export const network: Dapp.NetworkType = {
  id: "devnet",
  name: "Devnet",
  egldLabel: "xEGLD",
  walletAddress: "https://devnet-wallet.elrond.com",
  apiAddress: "https://devnet-api.elrond.com",
  gatewayAddress: "https://devnet-gateway.elrond.com",
  explorerAddress: "http://devnet-explorer.elrond.com/",
};
