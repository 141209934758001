import * as Dapp from "@elrondnetwork/dapp";
import { useHistory } from "react-router-dom";
import { useRemoveTokenData } from "components/AccessTokenProvider";
import { routeNames } from "routes";

const useLogout = () => {
  const dappLogout = Dapp.useLogout();
  const history = useHistory();
  const { removeTokenData } = useRemoveTokenData();

  return () => {
    removeTokenData();
    dappLogout({
      callbackUrl: `${window.location.origin}${routeNames.home}`,
    });
    history.push(routeNames.home);
  };
};

export default useLogout;
