import * as React from "react";
import { faTwitter, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { routeNames } from "routes";

const Home = () => {
  return (
    <div className="home d-flex flex-fill align-items-center container">
      <div className="row w-auto w-sm-100">
        <div className="col-12 col-md-8 mx-auto">
          <p className="heading d-flex flex-column align-items-center justify-content-center flex-md-row mb-5 pt-5">
            <span>
              <img src="/img/battle.svg" alt="Battle" />
            </span>
            <em>
              <img src="/img/of.svg" alt="of" />
            </em>
            <span>
              <img src="/img/yields.svg" alt="Yields" />
            </span>
          </p>

          <div className="text-white text-center mb-5">
            <p className="lead mb-3">Learn. Hack. Earn.</p>
            <p className="font-weight-light">
              Discover the potential of internet-scale DeFi.
              <br />
              The most scalable engine of the new internet economy is ready to
              be put to the test.
            </p>
            <p className="lead mb-n3">$100,000 USD Prize Pool</p>
          </div>

          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {/* <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 200 }}
              overlay={renderTooltip}
            >
              <span> */}
            <Link
              to={routeNames.enroll}
              className="btn btn-lg btn-light btn-cta"
              data-testid="loginBtn "
            >
              Account
            </Link>
            {/* </span>
            </OverlayTrigger> */}
            <a
              href="https://elrond.com/blog/battle-of-yields-announcement"
              target="_blank"
              rel="noreferrer nofollow"
              className="btn btn-lg btn-outline-light btn-cta"
            >
              Announcement
            </a>
          </div>

          <div className="text-center mb-3">
            <a
              href="https://leaderboard.battleofyields.com/"
              className="font-weight-light leaderboard"
            >
              Leaderboard
            </a>
          </div>

          <p className="heading d-flex flex-row align-items-center justify-content-center logo-text">
            <a
              href="https://testnet.maiar.exchange"
              title="Maiar Exchange"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <span className="mr-3">
                <img src="/img/maiar-logo.svg" alt="Maiar" />
              </span>
              <span className="exchange-text py-0">
                <img src="/img/maiar-exchange-text.svg" alt="exchange" />
              </span>
            </a>
          </p>
          <div className="social mt-3 d-flex align-items-center justify-content-center ml-spacer">
            <a
              target="_blank"
              className="mr-spacer mb-2 text-white"
              href="https://t.me/MaiarExchange"
              aria-label="Telegram"
              rel="noreferrer nofollow"
            >
              <FontAwesomeIcon icon={faTelegramPlane} />
            </a>
            <a
              target="_blank"
              className="mr-spacer mb-2 text-white"
              href="https://t.me/MaiarExchangeAnn/"
              aria-label="Telegram Announcements"
              rel="noreferrer nofollow"
            >
              <FontAwesomeIcon icon={faBullhorn} />
            </a>
            <a
              target="_blank"
              className="mr-spacer mb-2 text-white"
              href="https://twitter.com/MaiarExchange"
              aria-label="Twitter"
              rel="noreferrer nofollow"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
