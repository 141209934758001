import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import useLogout from "components/Layout/useLogout";
import ModalContainer from "components/ModalContainer";
import PageState from "components/PageState";
import useApiRequests from "helpers/useApiRequests";
import EnrollForm from "./EnrollForm";

const Enroll = () => {
  const { address } = Dapp.useContext();
  const { getEnrollment } = useApiRequests();
  const [enrolled, setEnrolled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [telegram, setTelegram] = React.useState<string>("");
  const [herotag, setHerotag] = React.useState<string>("");
  const [joinDate, setJoinDate] = React.useState<string>("");
  const [savedAddress, setSavedAddress] = React.useState<string>("");
  const logout = useLogout();

  const checkEnrollment = () => {
    getEnrollment().then(({ success, data }) => {
      if (success) {
        const { playAddress, telegramAccount, createdAt, username } = data;
        setTelegram(telegramAccount);
        setSavedAddress(playAddress);
        setJoinDate(createdAt);
        if (username) {
          setHerotag(username.replace(".elrond", ""));
        }
        setEnrolled(true);
        setLoading(false);
      } else {
        setEnrolled(false);
        setLoading(false);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(checkEnrollment, [address]);

  return (
    <ModalContainer title="">
      {loading && (
        <PageState
          title="Loading..."
          className="bg-primary text-white"
          spin={true}
          svgComponent={
            <>
              <FontAwesomeIcon
                icon={faCircleNotch}
                className="mx-auto text-white fa-3x fa-spin"
              />
            </>
          }
        />
      )}
      {!loading && !enrolled && (
        <PageState
          title="Enrollment ended"
          className="bg-warning text-white"
          description={<div className="pb-4">&nbsp;</div>}
          svgComponent={
            <>
              <FontAwesomeIcon
                icon={faTimes}
                className="mx-auto text-white fa-3x"
              />
            </>
          }
        />
      )}
      {!loading && enrolled && !showForm && (
        <>
          <h4 className="mt-n4 mb-4 text-center">Battle of Yields Account</h4>
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-lg-4 p-0 pr-lg-2 text-secondary text-left text-lg-right">
                Maiar login:
              </div>
              <div className="col-lg-8 p-0 pl-lg-2 text-left">
                {herotag ? `@${herotag}` : address}
              </div>
            </div>
            {savedAddress && (
              <div className="row mb-3">
                <div className="col-lg-4 p-0 pr-lg-2 text-secondary text-left text-lg-right">
                  Wallet address:
                </div>{" "}
                <div className="col-lg-8 p-0 pl-lg-2 text-left">
                  {savedAddress}
                </div>
              </div>
            )}
            {telegram && (
              <div className="row mb-3">
                <div className="col-lg-4 p-0 pr-lg-2 text-secondary text-left text-lg-right">
                  Telegram:
                </div>
                <div className="col-lg-8 p-0 pl-lg-2 text-left">{telegram}</div>
              </div>
            )}
            {joinDate && (
              <div className="row mb-3">
                <div className="col-lg-4 p-0 pr-lg-2 text-secondary text-left text-lg-right">
                  Joined on:
                </div>
                <div className="col-lg-8 p-0 pl-lg-2 text-left">
                  {moment.utc(joinDate).format("MMM Do YYYY, HH:mm:ss")} UTC
                </div>
              </div>
            )}
            <div className="mt-spacer">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => {
                  logout();
                }}
              >
                Disconnect
              </button>
            </div>
          </div>
        </>
      )}
      {!loading && enrolled && showForm && (
        <EnrollForm
          address={savedAddress}
          telegram={telegram}
          successText="Updated enrollment details."
          onBack={() => {
            setShowForm(false);
          }}
        />
      )}
    </ModalContainer>
  );
};

export default Enroll;
