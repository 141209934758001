import { StateType } from "./state";

export type ActionType =
  | {
      type: "setYieldsOrigin";
      yieldsOrigin: StateType["yieldsOrigin"];
    }
  | { type: "setTokenData"; tokenData: StateType["tokenData"] }
  | {
      type: "updateRefetch";
    };

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "setYieldsOrigin":
      return { ...state, yieldsOrigin: action.yieldsOrigin };
    case "updateRefetch": {
      return { ...state, refetch: Date.now() };
    }
    case "setTokenData": {
      return {
        ...state,
        tokenData: action.tokenData,
      };
    }
    default: {
      throw new Error("Unhandled action type");
    }
  }
}
